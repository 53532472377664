<template>
    <div>
      <el-row :gutter="20" class="search-box">
  
        <el-col :span="4">
          <el-select
            v-model="listQuery.status"
            placeholder="状态"
            clearable
            @change="getList(1)"
          >
           
            <el-option
              v-for="(item, index) in this.const.USER_DEVICE_STATUS"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
  
        <el-col :span="4">
          <el-input type="text" v-model="listQuery.bicycleMacId" placeholder="车辆二维码"></el-input>
        </el-col>
  
        <el-col :span="4">
          <el-input v-model="listQuery.batteryMacId" placeholder="电池编号"></el-input>
        </el-col>
   
        <el-col :span="4">
          <el-button type="primary" @click="getList(1)">查询</el-button>
        </el-col>
      </el-row>
  
      <BicycleBattery
        ref="BicycleBattery"
        :listQuery="listQuery"
        :pageable="true"
      ></BicycleBattery>
    </div>
  </template>
  
  <script>
  import BicycleBattery from "@/components/view-components/bicycle-battery";
  import { mapState } from "vuex";
  import moment from 'moment';
  
  export default {
    components: { BicycleBattery },
    computed: mapState({
      stationList: (state) => {
        return state.station.stationList || { list: [], map: {} };
      },
    }),
    data() {
      let startTime = moment().subtract(7, "d").startOf("d").format("YYYY-MM-DD HH:mm:ss");
      let endTime = moment().endOf("d").format("YYYY-MM-DD HH:mm:ss");
      
      return {
        dateRange: [startTime, endTime],
        listQuery: {
          
        },
      };
    },
    mounted() {
      this.getList(1);
    },
  
    methods: {
       changeDate(e) {
        if (!this.validatenull(e)) {
          this.listQuery.startTime = moment(e[0])
            .format("YYYY-MM-DD HH:mm:ss");
          this.listQuery.endTime = moment(e[1])
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          this.listQuery.startTime = "";
          this.listQuery.endTime = "";
        }
      },
      
      getList(current) {
        this.$refs["BicycleBattery"].getList(current);
      },
    },
  };
  </script>