<!-- 换电记录 -->
<template>
  <div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="车辆二维码" prop="bicycleMacId"></el-table-column>
      <el-table-column label="电池编号" prop="batteryMacId"></el-table-column>
      <el-table-column label="车牌号" prop="platenum"></el-table-column>
      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.USER_DEVICE_STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="补电时间" prop="createTime"></el-table-column>
      <el-table-column label="归还时间" prop="backTime"></el-table-column>
      <el-table-column label="当前电量" prop="curSoc"></el-table-column>
      <el-table-column label="消耗电量" prop="soc">
        <template #default="scope">
          <span
            >{{ scope.row.soc - scope.row.backSoc }}%</span
          >
        </template>
      </el-table-column>

      <el-table-column label="归还剩余电量" prop="backSoc">
        <template #default="scope">
          <span v-if="scope.row.backSoc">{{ scope.row.backSoc }}%</span>
        </template>
      </el-table-column>

    </el-table>

    <el-pagination
      v-if="pageable"
      layout="total, prev, pager, next"
      :page-sizes="[10, 50, 100, 200]"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>

</template>

<script>
import {
  toRefs,
  toRef,
  reactive,
  onMounted,
  isProxy,
  isReactive,
  computed,
  ref,
} from "vue";
import { fetchBicycleBatteryPage } from "@/api/bicycle";
import { validatenull } from "@/common/validate";
import { useStore } from "vuex";
import { deepClone } from "@/common/util";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter } from "vue-router";

export default {
  props: ["listQuery", "pageable"],
  components: {  },

  setup(props, context) {
    let listQuery = toRef(props, "listQuery");
    let router = useRouter();
    const store = useStore();
   
    const state = reactive({
      total: 0,
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      listQuery: listQuery,
    });
 
    const getList = (current) => {
      if (!validatenull(current)) {
        state.pages.pageIndex = current;
      }

      fetchBicycleBatteryPage({ ...state.listQuery, ...state.pages }).then(
        (res) => {
          state.dataSource = res.data.data.records;
          state.total = res.data.data.total;
        }
      );
    };

   

    return {
      ...toRefs(state),
      listQuery: listQuery.value,
      getList,
    };
  },
};
</script>